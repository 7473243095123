import React from 'react';

export default function OnBelay(){
    return(
        <>
        <video src='/on_belay_demo.mov' controls autoPlay={true} muted loop className='connect-four-vid'></video>

        <div className='project-desc'>
            <h2 className='font-inherit'>On Belay</h2>
            <p>
                On Belay is a fitness tracking app for rock climbing. This app was designed with the climber at the crag in mind. Therefore, the app follows the mobile first design principal, assuming that most people accessing the site will be physically on the wall or at the gym.<br/><br/>
                Personal progress in climbing is super measurable. All climbs have a difficulty rating, and you are always striving to get to the next level and attempt your next big move. The intention of this app is to allow climbers to record and visualize this progress over time. Climbing culture also revolves around the idea of Beta, or advice, on how to do or solve a climb. This app allows climbers to record their beta for themselves and for others on a given route. Moreover, its super fun to have quick access to your climbing history and remember all of the amazing things you've done.
    
                <br/>
                <br/>

                 You can do a bunch of cool stuff with this app, such as:
                 <ul>
                     <li>Log a climb, either as an indoor climb where you give details about the route, or as an outdoor climb that you log against a particular route</li>
                     <li>View your entire climb history</li>
                     <li>View all outdoor climbing routes that you or any other community member has created</li>
                     <li>View a routes page, see the routes details, post a comment, and see all of your climbs logged against that route</li>
                     <li>View your profile to get stats about your climbing career</li>
                 </ul>

                <br/>
                <br/>

                 Some upcoming features for this app
                 <ul>
                     <li>There will be a "Graphs" tab added to your profile where you where be able to visualize various performance based statistics in a time series</li>
                     <li>You will be able to search for specific routes and climbs on their respective pages</li>
                     <li>Climb Routes will be tagged with an climb area, such as Rumney</li>
                     <li>Routes will have a geo location with a link out to driving directions</li>
                     <li>Ultimately, we would also like to build out a mobile app equivalent using React Native</li>
                     <li>And even more!</li>
                 </ul>

                <br/>
                <br/>

                On Belay was created using: <br/><br/>
                <code>React</code>, <code>Flask</code>, <code>Postgres</code>, and <code>Python</code>. <br/><br/>
                With UX designs and assets from the amazing <a href="https://www.sophia-richardson.com/">Sophia Richardson</a>. Check out the <a href='https://github.com/ejgolden94/on-belay'>front end</a> or <a href='https://github.com/ejgolden94/on-belay-api'>backend</a> code on Github or visit the site <a href='https://on-belay.herokuapp.com/'>live</a>!
            </p>
            <div style={{margin:'5vh 0 '}}>
                <img src='/react_logo.png' alt='react' className='logo'/>
                <img src='/flask_logo.svg' alt='flask' className='logo'/>
                <img src='/postgres_logo.png' alt='postgres' className='logo'/>
                <img src='/python_logo.png' alt='python' className='logo'/>
            </div>
        </div>
        </>
    )
}