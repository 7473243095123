import 'semantic-ui-css/semantic.min.css'
import './App.css';
import Nav from './components/Nav';
import About from './components/About';
import Projects from './components/Projects';
import Resume from './components/Resume';
import Footer from './components/Footer';
import {BrowserRouter, Route, Switch} from 'react-router-dom'
import ConnectFour from './components/ConnectFour';
import LittleFox from './components/LittleFox';
import TrailBlazer from './components/TrailBlazer';
import OnBelay from './components/OnBelay';

function App() {
  return (
    <div className="App">
    <Nav />
    <BrowserRouter>
      <Switch>
      {/* Connect Four Project Page */}
        <Route path='/connect-four' render={(props) => <ConnectFour {...props} />}/>

      {/* Little Fox Project Page */}
        <Route path='/little-fox' render={(props) => <LittleFox {...props} />}/>

      {/* Trailblazer Project Page */}
        <Route path='/trailblazer' render={(props) => <TrailBlazer {...props} />}/>

      {/* On Belay Project Page */}
      <Route path='/on-belay' render={(props) => <OnBelay {...props} />}/>

      {/* Main Portfolio Page */}
          <Route path='/'>
            <div>
            <div className='App-header'>
              <div style={{fontSize: 'calc(10px + 2vmax)', margin:'1vh auto', display:'flex', justifyContent:'flex-start', width:'60%', maxWidth:'800px'}}>
                Hey there, I'm
              </div> 
              <div style={{margin:'1vh auto', display:'flex', justifyContent:'flex-start', width:'60%', maxWidth:'800px'}}>
                Ellyn
              </div> 
              <div style={{margin:'1vh auto', display:'flex', justifyContent:'flex-start', width:'60%', maxWidth:'800px'}}>
                Golden
              </div>
            </div>

            <About />

            <Projects />

            <Resume />

            </div>
          </Route>
        </Switch>
    </BrowserRouter>
    <Footer/>
    </div>
  );
}

export default App;
