import React from 'react';


export default function TrailBlazer(){
    return(
        <>
        <video src='/trailblazer_demo.mov' controls autoPlay={true} muted loop className='connect-four-vid'></video>

        <div className='project-desc'>
            <h2 className='font-inherit' >Trailblazer</h2>
            <p>
                Trailblazer is a meetups app for organizing outdoor trips. This app was designed to be a community based space where people could get together with other amateurs in the outdoors. We are hoping to bring people together through knowledge of place and love of nature who may otherwise never would have met. It is also a safe space for people who are not comfortable doing some activities in the outdoors on their own to find a larger and potentially more experienced group to adventure with. 
    
                <br/>
                <br/>

                 You can do a bunch of cool stuff with this app. You can view all of the outdoor trips available to join, and search for one in your area. You can login/ signup for an account to create an outdoor trip and add all of the relevant details. If you own the trip, you can edit or delete the trip. You can view the details of a specific outdoor trip and leave or join the trip. You can post a comment on the trip page where you can start a conversation with your fellow trippees. You can check out the current weather where the trip is located, powered by an external API. You can navigate to your own profile which will list all of your past and upcoming trips. You can also edit your personal profile and avatar. 

                <br/>
                <br/>

                Trailblazer was created using: <br/><br/>
                <code>Mongodb</code>, <code>Express</code>, <code>React</code>, and <code>NodeJS</code>. <br/><br/>
                Check out the <a href='https://github.com/nicholascrandall/Trailblazer'>front end</a> or <a href='https://github.com/nicholascrandall/Trailblazer_API'>backend</a> code on Github or visit the site <a href='https://trailblazers-app.herokuapp.com/'>live</a>!
            </p>
            <div style={{margin:'5vh 0 '}}>
                <img src='/mongodb_logo.png' alt='mongodb' className='logo'/>
                <img src='/express_logo.png' alt='express' className='logo'/>
                <img src='/react_logo.png' alt='react' className='logo'/>
                <img src='/nodejs_logo.svg' alt='nodejs' className='logo'/>
            </div>
        </div>
        </>
    )
}