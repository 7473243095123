import React from 'react';
import {Button, Divider, Header, Icon} from 'semantic-ui-react'
import useWindowSize from '../useWindowSize';

export default function Resume(){
    const {width} = useWindowSize()
    const direction= width<=1024? 'column' : 'row'
    const headerMargin = direction === 'row'? '3vh auto 2vh auto': '3vh 2vh'
    return(
        <>
        <div style={{minHeight:'80vh', marginTop:'10vh', marginBottom:'10vh'}}>
        <a name='Resume' href='#Resume' style={{color:'inherit'}}>
            <h2 className='font-inherit'>Resume</h2>
        </a>
        <a href="/egolden_resume.pdf" download>
            <Button color='teal' size='large' className='font-inherit'>Download PDF Resume</Button>
        </a>
        <div>

        <Divider horizontal/>
        {/* 
        ----------------------------------------------------
        --------------------- SKILLS -----------------------
        ----------------------------------------------------
        ----------------------------------------------------
         */}
        <Header as='h3' className='font-inherit' style={{margin: headerMargin, maxWidth: '900px', textAlign: 'left'}}>
            <Icon name='code' />
            SKILLS
        </Header>

        <div className='experience'>
            {direction==='row'? <div style={{width:'100px', marginLeft: '2vh'}}></div>:''}

            <ul style={{fontSize:'16px', margin:'1vh auto', maxWidth: '900px', textAlign:'left', lineHeight:'2'}}>
                <li><strong>Programming Languages:</strong> Javascript, HTML, CSS, Python, SQL</li>  
                <li><strong>Frameworks/Libraries:</strong> React, Bootstrap, Express, EJS, Mongoose, JQuery,  Node.js, Flask</li>
                <li><strong>Databases:</strong> MongoDB, Postgres, Amazon Redshift, Snowflake, Amazon S3</li>
                <li><strong>Tools:</strong> Matillion, Tableau, Looker, Excel, Github, Git, Fivetran, Stitch, CircleCI</li>
            </ul>
        </div>

        <Divider/>

        {/* 
        ----------------------------------------------------
        ------------------ EXPERIENCE ----------------------
        ----------------------------------------------------
        ----------------------------------------------------
         */}

        <Header as='h3' className='font-inherit' style={{margin:headerMargin, maxWidth: '900px', textAlign: 'left'}}>
            <Icon name='computer' />
            EXPERIENCE
        </Header>    

        <div className='experience' style={{flexDirection:direction}}>
            <div style={{width:'100px', marginRight: '1vh'}}>
                <img 
                    src='/ga_logo.png'
                    style={{width:'100px', height:'auto'}} 
                    alt='general assembly'   
                />
            </div>
            <div style={{margin:'2vh'}}>
                <Header as='h4' className='font-inherit' style={{margin:'3vh auto 1vh auto', maxWidth: '900px', textAlign: 'left'}}>
                    Software Engineering Fellow | General Assembly | Remote | Dec 2020 – June 2021
                </Header> 
                <ul style={{fontSize:'16px', margin:'1vh auto', maxWidth: '900px', textAlign:'left', lineHeight:'1.5'}}>
                    <li>Learned the fundamentals of full stack engineering</li>
                    <li>Trained in multiple programming languages including Javascript and Python</li>
                    <li>Created multiple full stack applications, version controlled via git and Github, and deployed online</li>
                    <li>Employed multiple front end and backend frameworks such as Flask, Express and React</li>
                    <li>Persisted data with databases such as MongoDB and Postgres</li>
                </ul>
            </div>
        </div>
        
        <div className='experience' style={{flexDirection:direction}}>
            <div style={{width:'100px', marginRight: '2vh'}}>
                <img 
                    src='/indigo_logo.png'
                    style={{width:'100px', height:'auto'}}    
                    alt='indigo agriculture'   
                />
            </div>
            <div style={{margin:'2vh'}}>
                <Header as='h4' className='font-inherit' style={{margin:'3vh auto 1vh auto', maxWidth: '900px', textAlign: 'left'}}>
                    Sr. Business Intelligence Engineer | Indigo Agriculture | Charlestown, MA | Dec 2018 – Present
                </Header> 
                <ul style={{fontSize:'16px', margin:'1vh auto', maxWidth: '900px', textAlign:'left', lineHeight:'1.5'}}>
                    <li>Promoted to Senior Engineer managing a team of three junior engineers July 2020 - Present</li>
                    <li>Builds and maintain data pipelines into Snowflake from sources such as S3 using technologies such as Snowpipe and Fivetran</li>
                    <li>Interfaces with product managers to build data resources into the software product roadmap</li>
                    <li>Supports the work of 40 analysts through data modeling efforts in Looker and Snowflake</li>
                    <li>Define business concepts as views in Data Warehouse (Snowflake) to be utilized by analytics org leveraging 175 different sources</li>
                    <li>Create Dashboards to support operational metrics for 300+ weekly Looker users</li>
                </ul>
            </div>
        </div>

        <div className='experience' style={{flexDirection:direction}}>
            <div style={{width:'100px', marginRight: '2vh'}}>
                <img 
                    src='/brightcove_logo.png'
                    style={{width:'100px', height:'auto'}}    
                    alt='brightcove'   
                />
            </div>
            <div style={{margin:'2vh'}}>
                <Header as='h4' className='font-inherit' style={{margin:'3vh 2vh', maxWidth: '900px', textAlign: 'left'}}>
                    Data Analyst, Business Intelligence | Brightcove Inc | Boston, MA | July 2017 –  Nov 2018 
                </Header> 
                <ul style={{fontSize:'16px', margin:'1vh auto', maxWidth: '900px', textAlign:'left', lineHeight:'1.5'}}>
                    <li>One of two developers of a data warehouse (Amazon Redshift)</li>
                    <li>Built data pipelines into the warehouse from sources such as Postgres, S3, and Salesforce using Matillion</li>
                    <li>Provided dashboards (Tableau) and insights for any internal data initiatives by anyone up to Executive Staff</li>
                    <li>Manipulated data via Python and SQL - i.e. projecting overages revenue in aid of 6O6 accounting process.</li>
                </ul>
            </div>
        </div>

        <Divider/>

        {/* 
        ----------------------------------------------------
        ------------------ EDUCATION -----------------------
        ----------------------------------------------------
        ----------------------------------------------------
         */}

        <Header as='h3' className='font-inherit' style={{margin:headerMargin, maxWidth: '900px', textAlign: 'left'}}>
            <Icon name='book' />
            EDUCATION
        </Header> 
        
        <ul style={{fontSize:'16px', margin:'1vh auto', maxWidth: '900px', textAlign:'left', lineHeight:'1.5'}}>
            <li><strong>General Assembly </strong>| Software Engineering Immersive Fellow | Remote | December 2020 - June 2021</li>
            <li><strong>Dartmouth College </strong>| Bachelor's of Arts in Environmental Engineering and Geo Sciences | Graduated Cum Laude | Hanover, NH | September 2013 - June 2017</li>
            <li><strong>Milton Academy </strong>| High School | Graduated Cum Laude | Milton, MA | September 2009 - June 2013</li>
        </ul>

        </div>
        </div>
        </>
    )
}