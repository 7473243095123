import React from 'react';
import { Image } from 'semantic-ui-react';

export default function Project(props){
    const {technologies, image, description, name} = props

    const technology = [
        {name:'js', image:'/js_logo.png'},
        {name:'css', image:'/css_logo.png'},
        {name:'html', image:'/html_logo.png'},
        {name:'jquery', image:'/jquery-logo.png'},
        {name:'express', image:'/express_logo.png'},
        {name:'mongodb', image:'/mongodb_logo.png'},
        {name:'react', image:'/react_logo.png'},
        {name:'nodejs', image:'/nodejs_logo.svg'},
        {name:'flask', image:'/flask_logo.svg'},
        {name:'python', image:'/python_logo.png'},
        {name:'postgres', image:'/postgres_logo.png'},
    ]

    return(
        // <div className='project'>
        <div>
            <Image 
                src={image}
                style={{margin:'2vh auto 1vh auto', border:'2px solid black', borderRadius: '2vh', width: '90%', maxHeight:'325px'}}
            />
            <div style={{minHeight:'7vh'}}>
                <p style={{fontSize:'18px', fontWeight:'700', textAlign:'left', marginLeft: '10%'}}>
                    {name}:
                </p>
                <p style={{fontSize:'18px', fontWeight:'500', textAlign:'left', marginLeft: '10%'}}>
                    {description}
                </p>
                <div className='tech-logos'>
                    {technologies.map(tech => {
                        const logo = technology.filter(element => element.name === tech)[0]
                        return <img src={logo.image} alt={logo.name} key={logo.name} className='logo'/>
                    })}
                </div>
            </div>
        </div>
    )
}