import React from 'react';
import Project from './Project'
import {Link} from 'react-router-dom'

export default function Projects(){
    return(
        <>
        <div style={{minHeight:'85vh'}}>
        <a name='Projects' href='#Projects' style={{color:'inherit'}}>
            <h2 className='font-inherit'>Some Pretty Neat Projects</h2>
        </a>
        <div className='projects'>
        <Link to='/connect-four' style={{color:'inherit'}} className='project'>
            <Project
                name='Connect Four'
                description='this project is a virtual rendition of the classic two player game.'
                technologies={['js','html','css','jquery']}
                image='/connect_four.png'
            />
        </Link>
        <Link to='/little-fox' style={{color:'inherit'}} className='project'>
            <Project
                name='Little Fox Cups and Cones'
                description='An ecommerce website for an icecream business'
                technologies={['js','express','mongodb']}
                image='/little_fox.png'
            />
        </Link>
        <Link to='/trailblazer' style={{color:'inherit'}} className='project'>
            <Project
                name='Trail Blazers'
                description='A social meetups site for coordinating outdoor adventures.'
                technologies={['mongodb','express','react','nodejs']}
                image='/trail_blazers.png'
            />
        </Link>
        <Link to='/on-belay' style={{color:'inherit'}} className='project'>
            <Project
                name='On Belay'
                description='fitness/progression tracking app for rock climbers'
                technologies={['postgres','flask','python','react']}
                image='/on_belay.png'
            />
        </Link>
        </div>
        </div>
        </>
    )
}