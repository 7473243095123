import React from 'react';
import 'pure-react-carousel/dist/react-carousel.es.css';

export default function LittleFox(){
    return(
        <>
        <video src='/little_fox_demo.mov' controls autoPlay={true} muted loop className='connect-four-vid'></video>


        <div className='project-desc'>
            <h2 className='font-inherit' >Little Fox Cups and Cones</h2>
            <p>
                Little Fox is a website for my sister who owns the <em>real</em> little fox cups and cones ice creamery in San Diego, CA. It's a love letter to her and her amazing design asthetic and work ethic. On this site as a customer, you can view all of her ice cream flavors, login, fill your cart with ice cream, and submit an order for pickup at a specific time. If you need to back out, you can cancel your order. <br/>
                <br/>
                As an admin user you can add new flavors, delete old flavors, edit any portion of the site, see all of the current orders you need to fill and update their statuses to let your customers know they're ready for pickup. 
                <br/>
                <br/>
                This app was created using: <br/><br/>
                <code>EJS</code>, <code>Express</code> and <code>Mongodb</code>. <br/><br/>
                Check out the code on <a href='https://github.com/ejgolden94/little-fox'>Github</a> or visit the site <a href='https://little-fox-cups-and-cones.herokuapp.com'>live</a>!
            </p>
            <div style={{margin:'5vh 0 '}}>
                <img src='/js_logo.png' alt='javascript' className='logo'/>
                <img src='/html_logo.png' alt='html' className='logo'/>
                <img src='/css_logo.png' alt='css' className='logo'/>
                <img src='/express_logo.png' alt='express' className='logo'/>
                <img src='/mongodb_logo.png' alt='mongodb' className='logo'/>
            </div>
        </div>
        </>
    )
}