import React from 'react';


export default function ConnectFour(){
    return(
        <>
        <video src='/connect_four_demo.mov' controls autoPlay={true} muted loop className='connect-four-vid'></video>

        <div className='project-desc'>
            <h2 className='font-inherit' >Connect Four</h2>
            <p>
                Connect Four is a virtual rendition of the classic 2 player game. You can play 1 other person, pick your team color, play multiple games in a row while keeping score, increase the board size up to Connect Five or Six, and track who goes next. If you forget how to play, we've provided a handy modal to remind you of the rules.
                <br/>
                <br/>
                This game was created using: <br/><br/>
                <code>Javascript</code>, <code>HTML</code>, <code>CSS</code>, and <code>JQuery</code>. <br/><br/>
                Check out the code on <a href='https://github.com/ejgolden94/connect-four'>Github</a> or visit the site <a href='https://ejgolden94.github.io/connect-four/'>live</a>!
            </p>
            <div style={{margin:'5vh 0 '}}>
                <img src='/js_logo.png' alt='javascript' className='logo'/>
                <img src='/html_logo.png' alt='html' className='logo'/>
                <img src='/css_logo.png' alt='css' className='logo'/>
                <img src='/jquery-logo.png' alt='jquery' className='logo'/>
            </div>
        </div>
        </>
    )
}