import React from 'react'
import useWindowSize from '../useWindowSize'
import {Image} from 'semantic-ui-react'

export default function About (){
    const {width} = useWindowSize()
     const direction = width <= 1024? 'column' : 'row'
    return(
        <>
        <div className='about-me font-inherit' style={{flexDirection:direction}}>
            <a name='About' href='#About' style={{color:'inherit'}}>
                <img className='profile-photo' src='/linkedinphoto.jpeg' alt='ellyn golden profile' />
            </a>
            <div className='about-me-details'>
                <h2 className='font-inherit'>About Me</h2>
                <p className='about-me-text'>I am a full-stack software engineer with a background in business intelligence and data engineering. My love of data and system architecture drives my ability to solve problems with the entire business in mind from end to end. I excel at communication, capable of conveying technical concepts to business stakeholders and distilling business requirements to technical solutions. Naturally gravitating towards leadership roles, I strive to enable the productivity of others through the optimization of processes and project management. 
                <br />
                <br />
                Outside of work, I am an outdoor enthusiast living in Boston, spending most of my time mountain biking with my partner and our husky.</p>
                <div className='social'>
                    <Image 
                        as='a' 
                        href="https://github.com/ejgolden94/" 
                        target="_blank" 
                        src='/github_mark_black.png'
                        style={{maxWidth:'30px', display:'inline-block', marginRight: '2vw'}}/>
                    <Image 
                        as='a' 
                        href="https://www.linkedin.com/in/ellyn-golden/" 
                        target="_blank" 
                        src='/LI-In-Bug.png'
                        style={{maxWidth:'30px', display:'inline-block'}}/>
                </div>
            </div>
        </div>
        </>
    )
}
